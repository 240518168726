// @flow strict
import { Link } from "gatsby";
import React from "react";
import Author from "./Author";
import Comments from "./Comments";
import Content from "./Content";
import Meta from "./Meta";
import styles from "./Post.module.scss";
import Tags from "./Tags";


const Post = ({ html, tagSlugs, slug, tags, title, date }) => {

  return (
    <div className={styles["post"]}>
      <Link className={styles["post__home-button"]} to="/page">
        All Articles
      </Link>

      <div className={styles["post__content"]}>
        <Content body={html} title={title} />
      </div>

      <div className={styles["post__footer"]}>
        <Meta date={date} />
        {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
        <Author />
      </div>

      <div className={styles["post__comments"]}>
        <Comments postSlug={slug} postTitle={title} />
      </div>
    </div>
  );
};

export default Post;
